/**
 * @file Homepage Hero.
 */

import { gsap } from 'gsap';
import KeenSlider from 'keen-slider';

/**
 * Homepage Hero component.
 */
const vHpHero = () => {
	const hpHero = document.querySelector('.v-hp-hero');
	const hpHeroSlider = hpHero.querySelector('.v-hp-hero__slider');

	if (!hpHero || !hpHeroSlider) {
		return;
	}

	const arrows = hpHero.querySelectorAll('.v-hp-hero__arrow');
	const currentLabel = hpHero.querySelector('.v-hp-hero__page');
	const postsWrap = hpHero.querySelector('.v-hp-hero__post');
	const posts = postsWrap.querySelectorAll('.v-hp-hero__post-inner');

	const keen = new KeenSlider( // eslint-disable-line no-unused-vars
		hpHeroSlider,
		{
			loop: true,
			drag: false,
			selector: '.v-hp-hero__slide',
			defaultAnimation: {
				duration: 3000,
			},
			detailsChanged: (s) => {
				s.slides.forEach((element, idx) => {
					element.style.opacity = s.track.details.slides[idx].portion; // eslint-disable-line no-param-reassign
				});
			},
			renderMode: 'custom',
		},
		[
			(slider) => {
				let timeout;

				/**
				 * Autoplay.
				 */
				function nextTimeout() {
					clearTimeout(timeout);
					timeout = setTimeout(
						() => {
							slider.next();
						},
						slider.track.details.rel === 0 ? 13000 : 6000,
					);
				}

				slider.on('created', () => {
					hpHeroSlider.classList.add('v-hp-hero__slider--loaded');

					arrows.forEach((arrow) => {
						const { dir } = arrow.dataset;
						arrow.addEventListener('click', () => {
							if (dir === '-1') {
								slider.prev();
							} else {
								slider.next();
							}
							nextTimeout();
						});
					});

					nextTimeout();
				});

				slider.on('slideChanged', () => {
					currentLabel.innerText = slider.track.details.rel + 1;
				});
				slider.on('animationEnded', nextTimeout);
				slider.on('updated', nextTimeout);

				if (postsWrap.classList.contains('v-hp-hero__post--slider')) {
					slider.on('animationStarted', (e) => {
						const currentId = e.track.details.rel;
						const targetId = e.track.absToRel(e.animator.targetIdx);

						const currentPost = posts[currentId];
						const targetPost = posts[targetId];
						gsap.set(targetPost, { display: 'block' });
						const targetHeight = gsap.getProperty(targetPost, 'height');
						gsap.set(targetPost, { display: 'none', opacity: 0 });
						gsap.set(postsWrap, {
							height: gsap.getProperty(postsWrap, 'height'),
						});

						gsap
							.timeline({
								defaults: {
									overwrite: true,
									duration: 0.3333,
									ease: 'sine.out',
								},
							})
							.to(currentPost, {
								opacity: 0,
								onComplete: () => {
									gsap.set(currentPost, { display: 'none' });
								},
							})
							.to(postsWrap, { height: targetHeight })
							.to(targetPost, {
								opacity: 1,
								onStart: () => {
									gsap.set(targetPost, { display: 'block' });
									gsap.set(postsWrap, { height: 'auto' });
								},
							});
					});
				}
			},
		],
	);
};

export default vHpHero;
