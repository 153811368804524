/**
 * @file Front Page.
 */

import vHpHero from '../../components/hp-hero/hp-hero';

/**
 * Front Page functions.
 *
 * Runs js specific to the Front Page template.
 */
const vFrontPage = () => {
	vHpHero();
};

if (document.readyState !== 'loading') {
	vFrontPage();
} else {
	document.addEventListener('DOMContentLoaded', vFrontPage);
}
